<template>
  <div id="auth" class="py0 evenly spaced flex">
    <div v-if="$mq != 'mobile'" class="illustration flex"></div>
    <div v-if="!registrationsClosed" class="myauto centered vertical flex">
      <div
        v-if="$bus.user != undefined"
        class="maxw500 px80 vertical flex form mxauto"
      >
        <button @click="logout">Logout</button>
      </div>
      <span v-else class="wfill maxw300 mxauto">
        <!-- LOGIN FORM -->
        <div v-if="state == 'login'" class="wfill px0 py80 vertical flex form">
          <h2 class="center mb12">Login to Ethos</h2>
          <label for="email">Email</label>
          <input type="text" name="email" placeholder="Email" v-model="email" />
          <label class="mt12" for="password">Password</label>
          <div class="relative">
            <input
              class="wfill"
              :type="passwordType"
              placeholder="Password"
              v-model="password"
            />
            <i
              @click="togglePasswordVisibility"
              style="right: 8px; height: 12px; top: calc(50% - 6px);"
              class="cursor pointer absolute hover-sss active-nnn eye icon"
            ></i>
          </div>
          <button
            class="primary mt12"
            v-if="redirectFrom"
            style="line-height: 20px;"
            @click="loginPreregistration(redirectFrom)"
          >
            Login<span> & Complete Event Registration</span>
          </button>
          <button
            class="mt12"
            :style="{ color: redirectFrom ? '#333' : 'white' }"
            :class="[redirectFrom ? 'f5f5f5' : 'brown500']"
            style="line-height: 20px; color: #333"
            @click="login"
          >
            Login<span v-if="redirectFrom"> to Ethos </span>
          </button>
          <!-- <div id="gSignIn" class="g-signin2" :data-onsuccess="onSignIn" data-width="300" data-height="200" data-longtitle="true"></div> -->
          <!-- <button 
            class="mt12"
            style="background: #23355B; font-weight: 300" 
            @click="fbLogin">Facebook</button>
          <button 
            class="mt12"
            style="background: #23355B; font-weight: 300" 
            @click="fbLogout">Logout</button> -->
          <a
            @click="state = 'forgotPassword'"
            class="blue center mt16 fs12 pointer"
            >Forgot Password</a
          >
          <a
            @click="state = 'createAccount'"
            class="blue center mt2 fs12 pointer"
            >Create an account</a
          >
          <div class="wfill vertical flex mb12" style="margin-bottom: -120px;">
            <button
              :style="{ width: $mq == 'mobile' ? '' : '250px' }"
              class="flex googleAuth mxauto mt20 px12 py6 white raised"
              @click="socialAuth(googleProvider)"
            >
              <img
                :height="$mq == 'mobile' ? 15 : 30"
                :class="[$mq == 'mobile' && 'mr12']"
                src="https://developers.google.com/identity/images/g-logo.png"
              />
              <span class="fw500 mauto" style="color: #7B7978;"
                >Sign in with Google</span
              >
            </button>
            <button
              :style="{ width: $mq == 'mobile' ? '' : '250px' }"
              class="flex nowrap facebookAuth mxauto mt20 mb6 py6 px12 py6 raised"
              @click="socialAuth(facebookProvider)"
            >
              <img
                :height="$mq == 'mobile' ? 15 : 30"
                :class="[$mq == 'mobile' && 'mr12']"
                src="https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/U3VrQWQBx7m.png"
              />
              <span class="mauto fw500 m" style="color: white;"
                >Continue with Facebook</span
              >
            </button>
            <!-- button 
                style="width: 250px;" 
                class="flex facebookAuth mxauto mt20 px12 py6 raised" @click="socialAuth(linkedInProvider)">
              <img height="30" src="https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/U3VrQWQBx7m.png">
              <span class="mauto fw500 m" style="color: white;">Continue with linkedIn</span>
            </button -->
          </div>
        </div>
        <!-- CREATE ACCOUNT FORM -->
        <div
          v-if="state == 'createAccount'"
          class="wfill px0 vertical flex form"
        >
          <h2 class="center mb20">Sign Up</h2>
          <div class="flex my12">
            <input
              v-model="first"
              type="text"
              placeholder="First"
              class="g6 mr8"
            />
            <input v-model="last" placeholder="Last" type="text" class="g6" />
          </div>
          <input type="email" placeholder="Email" v-model="email" />
          <div class="relative">
            <input
              class="mt12 wfill"
              :type="passwordType"
              placeholder="Password"
              v-model="password"
            />
            <i
              @click="togglePasswordVisibility"
              style="right: 8px; height: 12px; top: calc(50% - 2px);"
              class="cursor pointer absolute hover-sss active-nnn eye icon"
            ></i>
          </div>

          <input
            class="mt12"
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            v-model="contact"
            placeholder="Contact No. (123-456-7890)"
          />
          <!-- USER TYPE -->
          <label v-if="redirectFrom" class="mt4">User Type</label>
          <select v-if="redirectFrom" v-model="type" placeholder="User Type">
            <option selected disabled value="">User Type</option>
            <option v-for="t in userTypes" :key="t">{{ t }}</option>
          </select>

          <!-- label v-if="redirectFrom" for="">IIID Chapter</label>
          <select v-if="redirectFrom" v-model="IIIDChapter" placeholder="User Type">
            <option selected disabled value="">Select Chapter</option>
            <option value="Not Applicable"> Not Applicable </option>
            <option v-for="t in chapters" :key="t">{{t}}</option>
          </select -->

          <span v-if="type == 'Student'" class="vertical flex">
            <!-- LEVEL OF EDUCATION -->
            <label class="mt4">Education Level</label>
            <select v-model="levelOfEducation">
              <option selected disabled value="">Level of Education</option>
              <option v-for="l in educationLevels" :key="l" :value="l">{{
                l
              }}</option>
            </select>
            <span
              class="mt4 vertical flex"
              v-if="
                !['Middle School', 'High School', ''].includes(levelOfEducation)
              "
            >
              <span class="flex">
                <!-- FIELD OF STUDY -->
                <span class="mr8 grow">
                  <label class="mt4">Field of Study</label>
                  <select
                    class="mt4"
                    v-if="filteredFields"
                    v-model="fieldOfStudy"
                  >
                    <option selected disabled value="">Field of Study</option>
                    <option v-for="f in filteredFields" :key="f" :value="f">{{
                      f
                    }}</option>
                  </select>
                </span>

                <!-- QUACK -->
                <span class="grow">
                  <label class="mt4">Year of Graduation</label>
                  <select class="mt4 wfill" v-model="yearOfGraduation">
                    <option selected disabled value=""
                      >Year of Graduation</option
                    >
                    <option v-for="y in years" :key="y" :value="y">{{
                      y
                    }}</option>
                  </select>
                </span>
              </span>

              <!-- INSTITUTE -->
              <label class="mt4">Institute</label>
              <select v-model="institute">
                <option value="" selected disabled>Select Institute</option>
                <option value="Not Listed"> Not Listed </option>
                <option
                  v-for="(u, i) in filteredUniversities"
                  :key="i"
                  :value="u"
                  >{{ u }}</option
                >
              </select>

              <label v-if="institute == 'Not Listed'" class="mt12"
                >Institute Name</label
              >
              <input
                placeholder="Enter Institute Name"
                v-if="institute == 'Not Listed'"
                v-model="notListedInstitute"
              />
            </span>
          </span>
          <span v-if="type == 'Professional'" class="vertical flex">
            <!-- FIELD OF STUDY -->
            <label class="mt4">Employment Status</label>
            <select v-model="employmentStatus">
              <option selected disabled value="">Employment Status</option>
              <option v-for="f in employmentStatuses" :key="f" :value="f">{{
                f
              }}</option>
            </select>

            <span
              class="vertical flex"
              v-if="['Employed', 'Self Employed'].includes(employmentStatus)"
            >
              <label class="mt4">Field of Work</label>
              <select v-model="fieldOfStudy">
                <option selected disabled value="">Field of Work</option>
                <option
                  v-for="f in fields['Undergraduate']"
                  :key="f"
                  :value="f"
                  >{{ f }}</option
                >
              </select>

              <label class="mt4">Company Name</label>
              <input
                type="text"
                v-model="companyName"
                placeholder="Company Name"
              />

              <label class="mt4">Work Location</label>
              <select v-model="location">
                <option selected disabled value="">Select City</option>
                <option v-for="(c, i) in sortedCities" :key="i" :value="c">{{
                  c
                }}</option>
              </select>
            </span>
          </span>
          <span v-if="type == 'Faculty'" class="vertical flex">
            <!-- FIELD OF STUDY -->
            <label for="">Teaching Field</label>
            <select v-model="fieldOfStudy">
              <option selected disabled value="">Field</option>
              <option
                v-for="f in fields['Undergraduate']"
                :key="f"
                :value="f"
                >{{ f }}</option
              >
            </select>
            <!-- INSTITUTE -->
            <label for="">Institute</label>
            <select v-model="institute">
              <option value="" selected disabled>Institute</option>
              <option value="Not Listed">Not Listed</option>
              <option v-for="u in filteredUniversities" :key="u" :value="u">{{
                u
              }}</option>
            </select>

            <label v-if="institute == 'Not Listed'" class="mt12"
              >Institute Name</label
            >
            <input
              placeholder="Enter Institute Name"
              v-if="institute == 'Not Listed'"
              v-model="notListedInstitute"
            />
          </span>

          <button class="primary mt20" @click="createAccount">
            Create Account
          </button>
          <a @click="state = 'login'" class="blue center mt12 fs12 pointer"
            >Back to login</a
          >
          <div
            class="wfill vertical flex mb12"
            :style="{
              transform: mq == 'mobile' ? '' : 'scale(0.8)',
              marginBottom: '-120px'
            }"
          >
            <button
              :style="{ width: $mq == 'mobile' ? '' : '250px' }"
              class="flex googleAuth mxauto mt20 px12 py6 white raised"
              @click="socialAuth(googleProvider)"
            >
              <img
                :height="$mq == 'mobile' ? 15 : 30"
                :class="[$mq == 'mobile' && 'mr12']"
                src="https://developers.google.com/identity/images/g-logo.png"
              />
              <span class="fw500 mauto" style="color: #7B7978;"
                >Sign in with Google</span
              >
            </button>
            <button
              :style="{ width: $mq == 'mobile' ? '' : '250px' }"
              class="flex facebookAuth mxauto mt20 mb40 px12 py6 raised"
              @click="socialAuth(facebookProvider)"
            >
              <img
                :height="$mq == 'mobile' ? 15 : 30"
                :class="[$mq == 'mobile' && 'mr12']"
                src="https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/U3VrQWQBx7m.png"
              />
              <span class="mauto fw500 m" style="color: white;"
                >Continue with Facebook</span
              >
            </button>
          </div>
        </div>
        <!-- FORGOT PASSWORD FORM -->
        <div
          v-if="state == 'forgotPassword'"
          class="wfill px0 vertical flex form"
        >
          <h2 class="center mb32">Forgot Password</h2>
          <input type="text" placeholder="email" v-model="email" />
          <button @click="forgotPassword" class="mt20 primary">
            Send Password Reset Email
          </button>
          <a @click="state = 'login'" class="blue center mt12 fs12 pointer"
            >Back to login</a
          >
        </div>
        <!-- RESET PASSWORD FORM -->
        <div
          v-if="state == 'resetPassword'"
          class="wfill px0 vertical flex form"
        >
          <h2 class="center mb32">Reset Password</h2>
          <input
            type="password"
            placeholder="Password"
            v-model="password"
            class="mb16"
          />
          <input
            type="password"
            placeholder="Reenter Password"
            v-model="passwordCheck"
          />
          <button @click="resetPwd" class="primary mt20">Reset Password</button>
          <a @click="state = 'login'" class="blue center mt12 fs12 pointer"
            >Back to Login</a
          >
        </div>
      </span>
    </div>
    <div v-else class="centered vertical flex">
      <h2 class="mauto mb4">Registrations open September 15th</h2>
      <p class="mt4 mauto nnn">See you soon!</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import universities from "../universities";
import st from "storerestapi";
export default {
  name: "Auth",
  mixins: [universities],
  data() {
    let socialProviders = {};
    try {
      /* eslint-disable-next-line no-undef */
      var linkedInProvider = new firebase.auth.OAuthProvider("linkedin.com");
      linkedInProvider.addScope("r_emailaddress");
      linkedInProvider.addScope("r_liteprofile");

      socialProviders = {
        /* eslint-disable-next-line no-undef */
        googleProvider: new firebase.auth.GoogleAuthProvider(),
        /* eslint-disable-next-line no-undef */
        facebookProvider: new firebase.auth.FacebookAuthProvider(),
        /* eslint-disable-next-line no-undef */
        linkedInProvider
      };
    } catch (e) {
      console.log(e);
    }
    return {
      state: "login",
      first: "",
      last: "",
      email: "",
      passwordType: "password",
      password: "",
      passwordCheck: "",
      resetCode: "",
      yearOfGraduation: "",
      institute: "",
      fieldOfStudy: "",
      contact: "",
      levelOfEducation: "",
      country: "",
      type: "",
      companyName: "",
      location: "",
      employmentStatus: "",
      instituteState: "",
      notListedInstitute: "",
      educationLevels: [
        "Middle School",
        "High School",
        "Diploma",
        "Undergraduate",
        "Postgraduate",
        "Doctorate"
      ],
      employmentStatuses: ["Employed", "Self Employed", "None"],
      states: ["login", "signup", "forgotPassword", "resetPassword"],
      userTypes: ["Student", "Professional", "Faculty"],
      loggedIn: {
        facebook: false
      },
      redirectFrom: "",
      dbUnis: [],
      ...socialProviders
    };
  },
  mounted() {
    let ctx = this;
    this.redirectFrom = this.$route.query.redirectFrom;
    let state = this.$route.query.state;
    if (state) {
      this.state = state;
    }
    /* eslint-disable */
    $(document).scrollTop(0);

    try {
      var firebaseConfig = {
        apiKey: "AIzaSyCz4ypQyjtGsDhcp9g1gLnZjfZ-wgmYOug",
        authDomain: "ethosempowers-2281d.firebaseapp.com",
        projectId: "ethosempowers-2281d",
        storageBucket: "ethosempowers-2281d.appspot.com",
        messagingSenderId: "905484472591",
        appId: "1:905484472591:web:68aa7104b6a69c93d36e7a"
      };
      // Initialize Firebase
      /* eslint-disable-next-line no-undef */
      firebase.initializeApp(firebaseConfig);
    } catch (e) {
      console.log(e);
    }

    if (this.$route.params.state) this.state = this.$route.params.state;
    // eslint-disable-next-line no-undef
    $(".ui.dropdown")?.dropdown();
    this.renderGoogleButton();

    this.$req
      .get("universities?_limit=-1")
      .then(r => {
        this.dbUnis = r.data;
      })
      .catch(e => console.log(e));

    // // For facebook login
    window.fbAsyncInit = function() {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: "2732657787013536",
        cookie: true,
        xfbml: true,
        version: "v9.0"
      });
      // eslint-disable-next-line no-undef
      FB.AppEvents.logPageView();

      // eslint-disable-next-line no-undef
      FB.getLoginStatus(function(response) {
        // statusChangeCallback(response);
        if (response.status === "connected") {
          ctx.loggedIn.facebook = true;
        }
      });
    };

    // eslint-disable-next-line no-unexpected-multiline
    (function(d, s, id) {
      // eslint-disable-next-line no-undef
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    // eslint-disable-next-line no-undef
  },
  computed: {
    years: function() {
      if (!this.levelOfEducation || !this.levelOfEducation.includes("ate")) {
        return new Array(70).fill(1).map((n, i) => {
          return 1960 + i;
        });
      } else {
        return new Array(16).fill(1).map((n, i) => {
          return (
            parseInt(
              moment()
                .subtract(8, "years")
                .format("YYYY")
            ) + i
          );
        });
      }
    },
    registrationsClosed: function() {
      return false;
    },
    sortedCities: function() {
      let cities = JSON.parse(JSON.stringify(this.cities));
      return cities.sort();
    },
    filteredUniversities: function() {
      let key = {
        Architecture: "#a",
        "Interior Design": "#d",
        Planning: "#p",
        "Civil Engineering": "#c",
        Design: "#d"
      };

      if (Object.keys(key).includes(this.fieldOfStudy)) {
        var unis = this.dbUnis
          .filter(u => {
            if (u.categories) {
              return u.categories.includes(key[this.fieldOfStudy]);
            }
            return false;
          })
          .map(u => u.name + ", " + u.city);
        // .filter(u => u.state == this.form.instituteState)
      } else {
        unis = this.universities;
      }
      // if (['Architecture', 'Interior Design'].includes(this.fieldOfStudy)) {
      //   var unis = this.dbUnis.map(u => u.name + ', ' + u.city)
      // } else {
      //   unis = this.universities
      // }
      unis = [...unis];
      return unis.sort();
    },
    filteredFields: function() {
      if (this.fields[this.levelOfEducation]) {
        return this.fields[this.levelOfEducation];
      }
      return false;
    }
  },
  methods: {
    // fbLogin: function () {
    //   let ctx = this
    //   // eslint-disable-next-line no-undef
    //   FB.login(function(response) {
    //     // handle the response
    //     // eslint-disable-next-line no-undef
    //     FB.api(`/me`, {fields: 'email, name'}, function (response) {
    //       console.log('Good to see you, ' + response.name + '.', response);
    //     })

    //     ctx.$req.get('auth/facebook/callback')
    //       .then(r => console.log(r))
    //       .catch(e => console.log(e))
    //   }, {scope: 'email'})
    // },
    socialAuth: function(provider) {
      if (!provider) {
        return;
      }
      /* eslint-disable-next-line no-undef */
      firebase
        .auth()
        .signInWithPopup(provider)
        /* eslint-disable-next-line no-unused-vars */
        .then(result => {
          /** @type {firebase.auth.OAuthCredential} */
          let user = result.user;
          let token = user.uid;
          this.$req
            .post("socialauth", {
              uid: token
            })
            .then(r => {
              st.setVar("user", r.data.user);
              st.setVar("token", r.data.jwt);
              this.$bus.user = r.data.user;
              this.$bus.token = r.data.jwt;
              if (this.redirectFrom) {
                if (this.redirectFrom == "acegate") {
                  this.$router.push("/acegate");
                  return;
                }
                this.$router.push("/ideace/" + this.redirectFrom);
              } else {
                this.$router.push("/events");
              }
            })
            .catch(e => {
              alert("Authentication Failed");
              console.log(e);
            });
        })
        .catch(error => {
          console.log(`Authentication Failed: ${error.message}`);
        });
    },
    onSignIn: function(user) {
      // console.log(user);
    },
    renderGoogleButton: function() {
      /* eslint-disable no-undef */
      let ctx = this;
      if (!gapi) return;
      gapi.signin2.render("gSignIn", {
        onsuccess: function(user) {
          ctx.onSignIn(user);
        },
        onerror: function(err) {
          console.log("Google signIn2.render button err: " + err);
        }
      });
    },
    fbLogin: function() {
      // eslint-disable-next-line no-undef
      // FB.login(function(response) {
      //   console.log("Login Response", response);
      // });
      // passport.authenticate('facebook')()
    },
    checkLoginState: function() {
      // eslint-disable-next-line no-undef
      FB.getLoginStatus(function(response) {
        // See the onlogin handler
        if (response.status == "connected") {
          //eslint-disable-next-line no-undef
          FB.api("/me", function(response) {
            console.log("Successful login for: " + response.name);
          });
        } else {
          //eslint-disable-next-line no-undef
          FB.login(function(response) {
            console.log("Login Response", response);
          });
        }
      });
    },
    getErrorMessage: function(error) {
      try {
        let message = error.response.data.data[0].messages[0].message;
        return message;
      } catch {
        return error;
      }
    },
    login: function() {
      this.$baseReq
        .post("auth/local", {
          identifier: this.email,
          password: this.password
        })
        .then(r => {
          let user = r.data.user;
          delete user["quiz_entries"];
          delete user["qod_entries"];
          this.$bus.user = r.data.user;
          this.$bus.token = r.data.jwt;
          st.setVar("user", r.data.user);
          st.setVar("token", r.data.jwt);
          this.$router.push("/events");
        })
        .catch(error => {
          // Handle error.
          alert(this.getErrorMessage(error));
        });
    },
    loginPreregistration: function(event) {
      this.$baseReq
        .post("auth/local", {
          identifier: this.email,
          password: this.password
        })
        .then(r => {
          this.$bus.user = r.data.user;
          this.$bus.token = r.data.jwt;
          st.setVar("user", r.data.user);
          st.setVar("token", r.data.jwt);
          if (event == "acegate") {
            this.$router.push("/acegate");
            return;
          }
          this.$router.push("/ideace/" + event + `?preregister=true`);
        })
        .catch(error => {
          // Handle error.
          alert(this.getErrorMessage(error));
        });
    },
    createAccount: function() {
      let acc = {
        username: this.email,
        name: this.first + " " + this.last,
        email: this.email,
        password: this.password,
        type: this.type,
        levelOfEducation: this.levelOfEducation,
        yearOfGraduation: this.yearOfGraduation,
        institute: this.institute,
        notListedInstitute: this.notListedInstitute,
        field: this.fieldOfStudy,
        contact: this.contact,
        country: this.country,
        companyName: this.companyName,
        location: this.location,
        employmentStatus: this.employmentStatus
      };

      if (["Architecture", "Interior Design"].includes(acc.field)) {
        let uni = this.dbUnis.find(
          u => u.name + ", " + u.city == acc.institute
        );
        if (uni) {
          acc.university = uni.id;
        }
      }

      this.$baseReq
        .post("auth/local/register", acc)
        .then(() => {
          // Handle success.
          alert("Account created successfully");
          this.state = "login";
          this.$router.push("/auth/login");
        })
        .catch(error => {
          // Handle error.
          alert(this.getErrorMessage(error));
        });
    },
    forgotPassword: function() {
      this.$baseReq
        .post("auth/forgot-password", {
          email: this.email
        })
        .then(() => {
          // Handle success.
          alert("Reset password email sent.");
        })
        .catch(error => {
          // Handle error.
          alert(this.getErrorMessage(error));
        });
    },
    resetPwd: function() {
      if (this.password != this.passwordCheck) {
        alert("Passwords don't match");
        return;
      }
      this.$baseReq
        .post("auth/reset-password", {
          code: this.$route.query.code,
          password: this.password,
          passwordConfirmation: this.passwordCheck
        })
        .then(() => {
          alert("Password has been reset.");
          this.passwordCheck = "";
          this.password = "";
          this.$router.push(`/auth?state=login`);
          this.$req
            .post(
              `updateUser?id=${this.$bus.user.id}`,
              {
                updater: this.$bus.user.id
              },
              {
                headers: {
                  Authorization: `Bearer ${this.$bus.token}`
                }
              }
            )
            .then(() => {
              this.$bus.user = {
                ...this.$bus.user
              };
              st.setVar("user", this.$bus.user);
              this.$bus.notify("Profile Updated");
            })
            .catch(e => console.log(e));
        })
        .catch(error => {
          // Handle error.
          alert(this.getErrorMessage(error));
        });
    },
    togglePasswordVisibility: function() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    logout: function() {
      this.$bus.user = undefined;
      this.$bus.token = undefined;
      st.deleteVar("user");
      st.deleteVar("token");
    }
  }
};
</script>

<style lang="scss">
#auth {
  height: 100%;
}
#auth > div {
  width: 50%;
  height: 100%;
}
.illustration {
  background: url("../assets/main_mobile.jpg") center / contain no-repeat;
  width: 50%;
  height: 100%;
  min-height: 500px;
  border-radius: 28px;
  margin: 32px;
}
.form a {
  margin-top: 12px;
}
label {
  font-size: 11px;
  position: relative;
  top: 4px;
}
</style>
